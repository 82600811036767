@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
#proposal {
    font-family: 'Montserrat', sans-serif !important;
}

#proposal .shadow {
  -webkit-box-shadow: 0px 4px 10px -2px #000000;
  -moz-box-shadow: 0px 4px 10px -2px #000000;
  box-shadow: 0px 4px 10px -2px #000000;
}

#proposal .proposalTitleBackground {
    width: 100%;
    background-color: #67c5f0;
    clear: both;
    height: 53px;
    margin-bottom: 30px;
}

#proposal .proposalTitleBackground h5 {
    width: 40px;
    float: left;
    font-size: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
}
#proposal .proposalTitleBackground .bottom {
    color: #fff;
    font-size: 20px;
    padding: 12px;
}

#proposal .proposalModelNumber {
    float: left;
}

#proposal .proposalManufacturer {
    float: left;
}

#proposal .proposalTitle {
    color: #fff;
    //padding: 10px;
    margin-left: 30px;
}

#proposal .title {
    color: #67c5f0;
    font-weight: bold;
    font-size: 18px;
    margin: 20px 0px;
}

#proposalBackground {
    background-color: #67c5f0;
}

#proposal .dark {
    color: #013457;
    font-size: 18px;
}

table.loan {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.loan td, table.loan th {
  padding: 4px 10px;
}
table.loan tr.bordered {
  border-bottom: 1px solid #d3d3d3;
}
table.loan td.alignRight {
  text-align: right;
}
table.loan tbody td {
  //font-size: 18px;
}
table.loan tfoot td {
  font-size: 14px;
}
table.loan tfoot .links {
  text-align: right;
}
table.loan tfoot .links a{
  display: inline-block;
  background: #1C6EA4;
  color: #FFFFFF;
  padding: 2px 8px;
  border-radius: 5px;
}

#proposal .table-bordered>thead>tr>th, 
#proposal .table-bordered>tbody>tr>th, 
#proposal .table-bordered>tfoot>tr>th, 
#proposal .table-bordered>thead>tr>td, 
#proposal .table-bordered>tbody>tr>td, 
#proposal .table-bordered>tfoot>tr>td {
    margin: 0px;
    padding: 0px;    
}

#proposal .table > thead > tr > td.warning, 
#proposal .table > tbody > tr > td.warning, 
#proposal .table > tfoot > tr > td.warning, 
#proposal .table > thead > tr > th.warning, 
#proposal .table > tbody > tr > th.warning, 
#proposal .table > tfoot > tr > th.warning, 
#proposal .table > thead > tr.warning > td, 
#proposal .table > tbody > tr.warning > td, 
#proposal .table > tfoot > tr.warning > td, 
#proposal .table > thead > tr.warning > th, 
#proposal .table > tbody > tr.warning > th, 
#proposal .table > tfoot > tr.warning > th {
    background-color: #f4f4f4;
}
    
#proposalIntro {
    float: left;
    /* background-image: url('/images/EMTHome.png'); */
    background-size: 100% 450px;
    width: 100%;
}
    
#proposalIntro .mainTitle {
    color: white;
    margin-left: 20px;
    font-size: 40px;
}
#proposalIntro .subTitle {
    color: white;
    margin-left: 20px;
    font-size: 16px;
}

#proposal .utilitySummary {
    background-color: #f4f4f4;
    padding: 10px;
}
#proposal .systemOverviewImage {
    width: 100%;
}
#proposal .systemOverviewDetails{
    width: 100%;
}
    
.overrideWide {
    width: 100%;
}
.nextLine {
    clear: both;
    width: 100%;
}
.separateImage {
    float: right;
}


/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
/* Styles */
    #proposalIntro .mainTitle {
        font-size: 20px;
    }
    #proposal .utilitySummary {
        width: 100%;
        clear: both;
    }
    #proposal .proposalTitle {
        margin-left: 0px;
        padding: 14px;
    }
    #sizeChart {
        width: 250px;
    }
    #sizeChart1 {
        width: 250px;
    } 
    #sizeChart2 {
        width: 250px;
    }
    #proposal .proposalTitleBackground .bottom {
        padding: 20px 0 0 0;
        font-size: 10px;
    }
    h2 {
        font-size: 25px;
    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1123px) {
/* Styles */
    #proposalIntro .mainTitle {
        font-size: 40px;
    }
    #proposal .utilitySummary {
        width: 100%;
        clear: both;
    }
    #proposal .proposalTitleBackground .bottom {
        padding: 15px 0 0 0;
        font-size: 14px;
    }
    #sizeChart {
        width: 300px;
    }
    #sizeChart1 {
        width: 300px;
    } 
    #sizeChart2 {
        width: 250px;
    }

}

/* Desktops and laptops ----------- */
@media only screen and (min-width : 1224px) {
/* Styles */
    #proposalIntro .mainTitle, #proposalIntro .subTitle {
        margin-left: 50px;
    }
    .extraPadding {
        padding-right: 80px;
    }
    #actualCosts {
        width: 500px;
    }
    .overrideWide {
        width: 66.66666667%;
    }
    .nextLine {
        clear: none;
        width: 33.33333333%;
    }
    .separateImage {
        margin-top: 130px;
    }
    #sizeChart, #sizeChart1 {
        width: 400px;
    }
    #sizeChart1, #sizeChart2 {
        width: 400px;
    }
    #proposal .systemOverviewImage {
        width: 50%;
        float: left;
    }
    #proposal .systemOverviewDetails{
        width: 50%;
        float: left;
    }
}


/* Large screens ----------- */
@media only screen and (min-width : 1824px) {
}

#proposal .proposalTitleBackground .emtTitle,
#proposal .emtTitle{
    color: #275492;
}

#proposal.whiteBkg {
    background-color: #fff;
}

#proposal .proposalTitleBackground .emtYellow,
#proposal .emtYellow {
    color: #F7A22B;
}

#proposal .emtBkg{
    background-color: #275492;
}

#proposal .emtDark {
    color: #000;
    font-size: 18px;
}

.pageNumber{
    float:right;
    margin-top: -30px;
    font-size: 20px;
    margin-right: 20px;
}